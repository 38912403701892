import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { IonIcon, IonContent, IonHeader, IonButtons, IonPage, IonTitle, IonToolbar, IonButton, IonRow, IonCol, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonSpinner, IonList, IonItem, IonLabel, IonText, IonMenuButton } from '@ionic/react';
import { checkmarkCircleSharp, chevronForwardOutline, closeCircleSharp, timeSharp, warningSharp } from 'ionicons/icons';

import Mixpanel from 'libraries/Mixpanel';
import InfoHelper from 'libraries/InfoHelper';
import Authentication, { useUser } from 'libraries/Authentication';
import ModalController from 'libraries/ModalController';

import UserService from 'services/UserService';
import BillService from 'services/BillService';

import { Bill } from 'models/Bill';

import './Bills.scss';
import BillsDetails from 'pages/Bills/BillsDetails';

const Bills: React.FC = () => {
	const user = useUser();

	const [bills, setBills] = useState<Bill[] | undefined>();
	const [currentBill, setCurrentBill] = useState<Bill | undefined>();
	const [nextBill, setNextBill] = useState<Bill | undefined>();
	const [loadingBills, setLoadingBills] = useState(false);
	const [inTrial, setInTrial] = useState(false);

	async function getBills (reset?: boolean) {
		setLoadingBills(true);

		if (reset) {
			setBills(undefined);
		}

		try {
			const start = (!reset && bills && bills[bills.length - 1].id) || undefined;

			const newBills = await BillService.get(start);

			if (bills && !reset) {
				setBills([...bills, ...newBills]);
			}
			else {
				setBills(newBills);

				// Prepare var to store current bill
				let newCurrentBill;

				// Prepare var to store next bill
				let newNextBill;

				// Search for current bills
				for (let i = 0; i < newBills.length; i++) {
					const bill = newBills[i];

					// Check if bill started
					if (new Date(bill.dateSince) <= new Date()) {
						newCurrentBill = bill;

						if (i > 0) {
							newNextBill = newBills[i - 1];
						}

						break;
					}
				}

				// Check if still in tial
				if (newBills.length === 1 && newCurrentBill && newCurrentBill.status === 'pending') {
					setInTrial(true);
				}

				// Set currrent bill
				setCurrentBill(newCurrentBill);

				// Set next bill
				setNextBill(newNextBill);
			}
		}
		catch (error) {
			console.log(error);

			await InfoHelper.showErrorAlert();
		}

		setLoadingBills(false);
	}

	async function pay (year: boolean = false) {
		if (!currentBill) {
			return;
		}

		await InfoHelper.showLoading();

		try {
			Mixpanel.track('get_payment_link', { bill_id: currentBill.id });

			const { link } = await BillService.getPaymentLink(currentBill.id, year);

			window.open(link, '_self');

			await InfoHelper.hideLoading();
		}
		catch (error) {
			console.log(error);

			await InfoHelper.hideLoading();
			await InfoHelper.showErrorAlert();
		}
	}

	async function addPaymentMethod () {
		await InfoHelper.showLoading();

		try {
			Mixpanel.track('get_payment_method_link');

			const { link } = await UserService.getPaymentMethodLink();

			window.open(link, '_self');

			await InfoHelper.hideLoading();
		}
		catch (error) {
			console.log(error);

			await InfoHelper.hideLoading();
			await InfoHelper.showErrorAlert();
		}
	}

	async function updateSubscription (status: 'active' | 'inactive') {
		let header, subHeader;

		if (status === 'active') {
			header = 'Reactivar suscripción';
			subHeader = '¿Seguro que quiere reactivar su suscripción?';
		}
		else if (status === 'inactive') {
			header = 'Desactivar suscripción';
			subHeader = '¿Seguro que quiere desactivar su suscripción?';
		}

		InfoHelper.showAlert({
			header,
			subHeader,
			buttons: ['Cancelar', {
				text: 'Continuar',
				handler: async () => {
					await InfoHelper.showLoading();

					try {
						const user = await UserService.updateSubscriptionStatus(status);

						Mixpanel.track('subscription_update', { status });

						Authentication.user = user;

						getBills(true);

						await InfoHelper.hideLoading();
					}
					catch (error) {
						console.log(error);

						await InfoHelper.hideLoading();
						await InfoHelper.showErrorAlert();
					}
				}
			}]
		});
	}

	useEffect(() => {
		getBills();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		(async () => {
			Mixpanel.track('BillsPage');
		})();
	}, []);

	return (
		<IonPage className="bills-page">
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Suscripción</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>

				{/* Payment method required */}
				{user && !user.hasPaymentMethod && user.subscriptionStatus === 'active' && <IonRow className="ion-justify-content-center">
					<IonCol size="12" size-md="7" size-lg="6" className='ion-no-padding'>
						<IonCard>
							<IonCardContent className='ion-text-center'>
								<h2 className='ion-text-center ion-margin ion-padding-bottom'>
									<IonIcon style={{ fontSize: '40px' }} color='danger' icon={warningSharp} />
									<br />
									<small>
										Debes agregar un metodo de pago antes de continuar con el uso del SDK.
									</small>
									<br />
									<br />
									No se realizara ningun cargo hasta el dia de vencimiento
								</h2>
								<IonButton
									size='default'
									onClick={() => addPaymentMethod()}>
									Agregar metodo de pago
								</IonButton>

							</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>}

				{user && user.billingPlan !== 'plan_e' && user.billingPlan !== 'plan_f' && <IonCard>
					<IonCardHeader>
						<IonCardSubtitle>
							Tus pagos
						</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						{/* Billing */}
						{
							user && <IonRow>
								<IonCol size='12' className='ion-no-padding'>
									{user.billingPlan === 'plan_a' && <small>
										El costo <u>mensual</u> de uso del SDK en produccion es de <b>1 USD por CUIT</b>.
										<br />
										<br />
										<u>En modo dev no aplica ningun costo.</u>
									</small>}

									{user.billingPlan === 'plan_b' && <small>
										El costo <u>mensual</u> de uso del SDK en produccion es
										<br />
										<br />
										<b>USD 5 hasta 10 CUITs</b> {/* (USD 0.5 x CUIT) */}
										<br />
										<b>USD 20 hasta 100 CUITs</b> {/* (USD 0.2 x CUIT) */}
										<br />
										<b>USD 100 hasta 1000 CUITs</b> {/* (USD 0.1 x CUIT) */}
										<br />
										<br />
										<u>En modo dev no aplica ningun costo.</u>
									</small>}

									{user.billingPlan === 'plan_c' && <small>
										El costo de uso del SDK en produccion es <b>USD 24.5 <u>mensual</u></b>, pagado anualmente.
										<br />
										<br />
										<u>En modo dev no aplica ningun costo.</u>
									</small>}

									{user.billingPlan === 'plan_d' && <small>
										El costo de uso del SDK en produccion es <b><u>USD 148.75 mensual</u> </b>, pagado anualmente.
										<br />
										<br />
										<u>La creacion de PDF siempre cuenta como uso en produccion.</u>
									</small>}

									{user.billingPlan === 'plan_pro' && <small>
										Tenes acceso gratis al uso del SDK en produccion por haber adquirido la antigua version PRO
									</small>}
								</IonCol>
								<IonCol size='12' className="ion-text-center ion-no-padding ion-padding-top">
									{/* Bills */}
									{bills && bills.length > 0 && <IonList lines='none'>
										{bills.map(bill => <IonItem
											key={bill.id}
											className='bill'
											onClick={() => ModalController.create(<BillsDetails bill={bill}></BillsDetails>)}>
											<IonLabel>
												<small>
													Monto: <b>{bill.amount.currency} {bill.amount.amount}</b>
													<br />
													Mes: <b>{moment(bill.dateSince).format('MM/YY')}</b>
												</small>
											</IonLabel>
											<div className='ion-text-right'>
												<small>
													Estado: <b>{bill.statusFormatted}</b>
													<br />
													Vencimiento: <b>{moment(bill.expiration).format('DD/MM/YY')}</b>
													{
														moment().isAfter(moment(bill.expiration)) && bill.status === 'pending' && <>
															<br />
															<IonText color='danger'><b>Pago vencido</b></IonText>
														</>
													}
												</small>
											</div>
										</IonItem>)}
									</IonList>}

									{/* Empty text */}
									{bills && bills.length === 0 && <IonRow className="ion-text-center">
										<IonCol>
											Tus pagos van a aparecer aca a comienzos de mes
										</IonCol>
									</IonRow>}

									{/* Loading */}
									{(!bills || loadingBills) && <IonRow className="ion-text-center ion-padding-top">
										<IonCol><IonSpinner /></IonCol>
									</IonRow>}
								</IonCol>
							</IonRow>
						}

						{/* Loading */}
						{!user && <IonRow className="ion-text-center ion-padding-top">
							<IonCol><IonSpinner /></IonCol>
						</IonRow>}
					</IonCardContent>
				</IonCard>}

				{user && (user.billingPlan === 'plan_e' || user.billingPlan === 'plan_f') && <>
					{inTrial && user.subscriptionStatus === 'active' && <h2 className='ion-text-center ion-margin'>
						<IonText color='dark'>
							<b>Tenes 14 dias de prueba</b>
							<br />
							<small>
								<small>
									La suscripción puede ser usada sin limite de CUITs ni requests
								</small>
							</small>
							{
								user.subscriptionStatus === 'active' && <small>
									<br />
									Podes cancelar tu suscripción en cualquier momento sin incurrir en costos
								</small>
							}
						</IonText>
					</h2>}

					{user.subscriptionStatus === 'inactive' && <h2 className='ion-text-center ion-margin'>
						<IonIcon color='danger' icon={closeCircleSharp} />
						<br />
						Suscripción inactiva
					</h2>}

					{!inTrial && currentBill && user.subscriptionStatus === 'active' && <h2 className='ion-text-center ion-margin'>

						{currentBill.status === 'paid' && <>
							<IonIcon color='success' icon={checkmarkCircleSharp} />
							<br />
							Pagado
						</>}

						{currentBill.status === 'pending' && <>
							<IonIcon color='warning' icon={timeSharp} />
							<br />
							Pago pendiente
						</>}
					</h2>}

					{/* <IonRow className="ion-justify-content-center">
						<IonCol size="12" size-md="7" size-lg="6" className='ion-no-padding'>
							<IonCard>
								<IonCardContent>
									<IonRow>
										<IonCol class='ion-text-center'>
											<small>
													Necesitamos tu feedback! <a href="https://calendly.com/afipsdk/feedback-de-10-minutos" target='_blank' rel="noreferrer">Agenda una llamada</a> de 10 min con nosotros y obtene un mes gratis! 
											</small>
										</IonCol>
									</IonRow>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow> */}

					<IonRow className="ion-justify-content-center">
						<IonCol size="12" size-md="7" size-lg="6" className='ion-no-padding'>
							<IonCard>
								<IonCardContent>
									{/* Current bill */}
									{
										currentBill && <div className='ion-text-center'>
											{currentBill.status === 'pending' && user.subscriptionStatus === 'active' && <>
												<div>
													<small>
														El costo de uso del SDK en produccion es <b>{currentBill.amount.currency} {(currentBill.anualAmount.amount / 12).toFixed(2)} mensual</b>, pagado anualmente.
													</small>
													<br />
													<br />
													<IonButton size='default' onClick={() => pay(true)}>Pagar un año</IonButton>
													<br />
													<br />
													<IonButton size='small' onClick={() => pay()}>Pagar un mes a {currentBill.amount.currency} {currentBill.amount.amount}</IonButton>
												</div>
												<br />
												<small>
													Paga tu suscripción antes del <b>{moment(currentBill.expiration).format('DD/MM/YY')}</b> para continuar usando el servicio
												</small>
											</>}

											{currentBill.status === 'pending' && user.subscriptionStatus === 'inactive' && <>
												<small>
													Podes seguir usando tu suscripción hasta el <b>{moment(currentBill.expiration).format('DD/MM/YY')}</b>
												</small>
											</>}

											{currentBill.status === 'paid' && nextBill && user.subscriptionStatus === 'inactive' && <>
												<small>
													Podes seguir usando tu suscripción hasta el <b>{moment(nextBill.expiration).format('DD/MM/YY')}</b>
												</small>
											</>}

											{currentBill.status === 'paid' && nextBill && user.subscriptionStatus === 'active' && <>
												<div>
													Tu suscripción esta paga hasta el <b>{moment(nextBill.expiration).format('DD/MM/YY')}</b>
												</div>
												<br />
												<small>
													Podras renovarla a partir del <b>{moment(nextBill.dateSince).format('DD/MM/YY')}</b>
												</small>
											</>}
										</div>
									}

									{/* Loading */}
									{!currentBill && <IonRow className="ion-text-center ion-margin">
										<IonCol><IonSpinner /></IonCol>
									</IonRow>}
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol className='ion-padding ion-text-end'>
							<span className='text-middle'>
								Suscripción&nbsp;
							</span>
							{user.subscriptionStatus === 'active'
								? <>
									<span className='text-middle'>activa </span>
								</>
								: <>
									<span className='text-middle'>inactiva </span>
								</>}

							{
								user.subscriptionStatus === 'active'

									// Cancel suscription
									? <IonButton onClick={() => updateSubscription('inactive')} size='small' color='danger' className='ion-margin-start text-middle'>
										Cancelar suscripción
									</IonButton>

									// Activate suscription
									: <IonButton onClick={() => updateSubscription('active')} size='small' color='primary' className='ion-margin-start text-middle'>
										Reactivar suscripción
									</IonButton>
							}
						</IonCol>
					</IonRow>

					{bills && bills.length > 1 && <IonRow className='ion-margin-bottom'>
						{/* Payments header */}
						<IonCol size='12' className="ion-text-center ion-no-padding">
							{/* Bills */}
							{bills && bills.length > 0 && <IonList>
								<IonItem>
									<h2 className='text-middle'><IonIcon className='text-middle' icon={chevronForwardOutline}></IonIcon> <span className='text-middle'>Historial de pagos</span></h2>
								</IonItem>
								{
									bills.map(bill => 
										new Date(bill.dateSince) <= new Date() && 
										(bill.status === 'paid' || user.subscriptionStatus === 'active') && 
										<IonItem
											key={bill.id}>
											<IonLabel>
												<small>
											Monto: <b>{bill.amount.currency} {bill.amount.amount}</b>
													<br />
											Periodo: <b>{moment(bill.dateSince).format('DD/MM/YY')}</b>
											&nbsp;-&nbsp;<b>{moment(bill.dateUntil).format('DD/MM/YY')}</b>
												</small>
											</IonLabel>
											<div className='ion-text-right'>
												<small>
											Estado: <b>{bill.statusFormatted}</b>
													<br />
											Vencimiento: <b>{moment(bill.expiration).format('DD/MM/YY')}</b>
													{
														moment().isAfter(moment(bill.expiration)) && bill.status === 'pending' && <>
															<br />
															<IonText color='danger'><b>Pago vencido</b></IonText>
														</>
													}
												</small>
											</div>
										</IonItem>
									)
								}
							</IonList>}

							{/* Empty text */}
							{bills && bills.length === 0 && <IonRow className="ion-text-center">
								<IonCol>
									Tus pagos van a aparecer aca a comienzos de mes
								</IonCol>
							</IonRow>}

							{/* Loading */}
							{(!bills || loadingBills) && <IonRow className="ion-text-center ion-padding-top">
								<IonCol><IonSpinner /></IonCol>
							</IonRow>}
						</IonCol>
					</IonRow>}
				</>}

				{/* Loading */}
				{!user && <IonRow className="ion-text-center vertical-center">
					<IonCol><IonSpinner /></IonCol>
				</IonRow>}

			</IonContent>
		</IonPage>
	);
};

export default Bills;
