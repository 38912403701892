import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('User')
export class User {
	@JsonProperty('id', String) id: string = '';
	@JsonProperty('access_token', String) accessToken: string = '';
	@JsonProperty('email', String) email: string = '';
	@JsonProperty('created_at', String) createdAt: string = '';
	@JsonProperty('billing_plan', String) billingPlan: string = '';
	@JsonProperty('subscription_status', String) subscriptionStatus: string = '';
	@JsonProperty('has_payment_method', Boolean) hasPaymentMethod: boolean = false;
}
