import React, { useEffect } from 'react';
import { IonContent, IonPage, IonButton, IonRow, IonCol, IonText } from '@ionic/react';

import BillService from 'services/BillService';
import InfoHelper from 'libraries/InfoHelper';

import { Bill } from 'models/Bill';

import './BillsDetails.scss';
import moment from 'moment';
import Mixpanel from 'libraries/Mixpanel';

interface BillsDetailsProps {
	bill: Bill;
};

const BillsDetails: React.FC<BillsDetailsProps> = ({ bill }) => {
	async function pay (year: boolean = false) {
		await InfoHelper.showLoading();

		try {
			Mixpanel.track('get_payment_link', { bill_id: bill.id });

			const { link } = await BillService.getPaymentLink(bill.id, year);

			window.open(link, '_blank');
		
			await InfoHelper.hideLoading();
		}
		catch (error) {
			console.log(error);

			await InfoHelper.hideLoading();
			await InfoHelper.showErrorAlert();
		}
	}

	useEffect(() => {
		Mixpanel.track('BillsDetailsPage', { bill_id: bill.id });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<IonPage className="bills-details-page">
			<IonContent className="ion-padding">
				<IonRow className="ion-text-center">
					<IonCol>
						<b>Detalles del pago</b>
					</IonCol>
				</IonRow>

				<IonRow className="ion-text-center">
					<IonCol>
						<div className="vertical-center">
							<small><b>Monto</b></small>
							<br />
							{ bill.amount.currency } { bill.amount.amount }
						</div>
					</IonCol>
					<IonCol>
						<div className="vertical-center">
							<small><b>Mes</b></small>
							<br />
							{moment(bill.dateSince).format('MM/YY')}
						</div>
					</IonCol>
					<IonCol>
						<div className="vertical-center">
							<small><b>Estado</b></small>
							<br />
							{bill.statusFormatted}
						</div>
					</IonCol>
				</IonRow>
				<hr />
				
				{bill.status === 'pending' && <IonRow class="text-m ion-text-center rating">
					<IonCol>
						<div className="vertical-center">
							<b>Vencimiento:</b> <span>{moment(bill.expiration).format('DD/MM/YY')}</span>
						</div>
					</IonCol>
					{!bill.enableAnualPayment && <IonCol>
						<div className="vertical-center">
							<IonButton size='small' onClick={e => pay()}>Pagar</IonButton>
						</div>
					</IonCol>}
					{bill.enableAnualPayment && <IonCol>
						<div>
							<IonText color='success'><b>¡Ahorra { bill.amount.currency } { ((bill.amount.amount * 12) - bill.anualAmount.amount).toFixed(2) } pagando un año!</b></IonText>
							<br />
							<br />
							<IonButton size='default' onClick={e => pay(true)}>Pagar un año por { bill.amount.currency } { (bill.anualAmount.amount).toFixed(2) }</IonButton>
						</div>					
						<div>
							<IonButton fill='clear' size='small' onClick={e => pay()}>Pagar mes por { bill.amount.currency } { bill.amount.amount }</IonButton>
							<br />
						</div>
					</IonCol>}
					{/* <IonCol size='12'>
						<div className="vertical-center">
							<IonText color='warning'><b>Si ya realizaste el pago espera a que se acredite, puede tardar un poco</b></IonText>
						</div>
					</IonCol> */}
				</IonRow>}
				{bill.status === 'pending' && <hr />}

				<table>
					<thead>
						<tr className="ion-text-left">
							<th>CUIT</th>
							<th>Usos en el mes</th>
						</tr>
					</thead>
					<tbody>
						{bill.userTaxIds?.map((userTaxId, i) => <tr key={i}>
							<td>{ userTaxId.userTaxId.taxId }</td>
							<td>{ userTaxId.usage }</td>
						</tr>)}
					</tbody>
				</table>
			</IonContent>
		</IonPage>
	);
};

export default BillsDetails;
