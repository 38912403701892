import React from 'react';
import { useLocation } from 'react-router-dom';
import { logOutOutline, cashOutline, homeOutline } from 'ionicons/icons';
import {
	IonCard,
	IonCardContent,
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonRow,
	IonTitle,
	IonToolbar
} from '@ionic/react';

import InfoHelper from 'libraries/InfoHelper';
import Authentication from 'libraries/Authentication';
import Mixpanel from 'libraries/Mixpanel';

import './Style/Menu.scss';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	permissions?: string[];
}

const appPages: AppPage[] = [
	{ title: 'Inicio', url: '/', icon: homeOutline },
	{ title: 'Suscripción', url: '/billing', icon: cashOutline }
];

const Menu: React.FC = () => {
	const location = useLocation();

	function logout () {
		InfoHelper.showAlert({
			header: 'Cerrar session',
			subHeader: '¿Seguro que quiere cerrar sesion?',
			buttons: ['Cancelar', {
				text: 'Cerrar sesion',
				handler: async () => {
					await InfoHelper.showLoading();

					try {
						await Authentication.logout();

						await Mixpanel.track('logout');

						// Reload page unauthenticated
						window.location.reload();
					}
					catch (error) {
						console.log(error);

						await InfoHelper.hideLoading();
						await InfoHelper.showErrorAlert();
					}
				}
			}]
		});
	}

	return (
		<IonMenu contentId="main" type="overlay">
			<IonHeader>
				<IonToolbar>
					<IonTitle>
						<div>
							<img alt="AfipSDK" className='app-icon text-middle' src="/assets/icon/favicon.png" /><span className='text-middle'>Afip SDK</span>
						</div></IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList id="inbox-list">
					{appPages.map((appPage, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="root" lines="none" detail={false}>
									<IonIcon slot="start" icon={appPage.icon} />
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>
			</IonContent>
			<IonFooter>
				<IonList>
					<IonMenuToggle autoHide={false}>
						<IonItem lines="none" detail={false} onClick={logout} className="ion-activatable cursor-pointer">
							<IonIcon slot="start" icon={logOutOutline} />
							<IonLabel>Cerrar sesión</IonLabel>
						</IonItem>
					</IonMenuToggle>
				</IonList>

				<IonToolbar>
					<IonCard>
						<IonCardContent>
							<IonRow class='help'>
								<IonCol size='3'>
									<img alt="support" className='avatar' src="/assets/images/ivan.jpg" />
								</IonCol>
								<IonCol>
									<IonLabel>
										<b>Hola soy Ivan</b> 👋🏼
										<br />
										<small>
											Por cualquier duda o consulta podes escribirme a <u>ivan@afipsdk.com</u>
										</small>
									</IonLabel>
								</IonCol>
							</IonRow>
						</IonCardContent>
					</IonCard>
				</IonToolbar>
			</IonFooter>
		</IonMenu>
	);
};

export default Menu;
