import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonLoading, IonModal } from '@ionic/react';

import Authentication, { useIsAuthenticated, useUser } from 'libraries/Authentication';
import InfoHelper, { useIsLoadingShown } from 'libraries/InfoHelper';
import { useActiveModals } from 'libraries/ModalController';
import Mixpanel from 'libraries/Mixpanel';
import Menu from 'components/Menu';
import Bills from 'pages/Bills/Bills';

const Home = lazy(() => import('./pages/Home/Home'));
const Login = lazy(() => import('./pages/Login/Login'));

const App: React.FC = () => {
	const showLoading = useIsLoadingShown();
	const isAuthenticated = useIsAuthenticated();
	const user = useUser();
	const [appInitialized, setAppInitialized] = useState(false);
	const [appError, setAppError] = useState(false);
	const activeModals = useActiveModals();

	// Initialize app
	useEffect(() => {
		(async () => {
			try {
				await Authentication.initialize();
				await Mixpanel.init();

				Mixpanel.track('InitApp');

				// InfoHelper.showAlert({ 
				// 	header: 'Cronometro de respiracion',
				// 	subHeader: 'Ingresa los segundos',
				// 	inputs: [
				// 		{ type: 'text', name: 'config', value: '478'}
				// 	],
				// 	buttons: [
				// 		'Cancel',
				// 		{
				// 			text: 'Comenzar',
				// 			handler: ({config}) => {
				// 				const [one, two, three] = config.toString().split('').map((n: any) => parseInt(n));
				// 				let remainSeconds = one;
				// 				let secondsOrder = [two, three, one];

				// 				let lastCheckDate = Date.now();
				// 				setInterval(() => {
				// 					if(remainSeconds === 0) {
				// 						const [two, three, one] = secondsOrder;

				// 						remainSeconds = two;
				// 						secondsOrder = [three, one, two];

				// 						InfoHelper.playSoundAlert();
				// 					}

				// 					let currentDate = Date.now();

				// 					if (currentDate - lastCheckDate >= 1000) {
				// 						lastCheckDate = currentDate;
				// 						remainSeconds--;
				// 						console.log(remainSeconds)
				// 					}

				// 				}, 10);

				// 			}
				// 		}
				// 	]
				// });

				setAppInitialized(true);
			}
			catch (error) {
				console.log(error);

				InfoHelper.showErrorAlert({ 
					header: 'Ah ocurrido un error',
					subHeader: 'Intente cargar la pagina nuevamente',
					backdropDismiss: false
				});

				setAppError(true);
			}
		})(); 
	}, []);

	return (
		<IonApp>
			<IonLoading isOpen={showLoading || (!appInitialized && !appError)} />
			
			{appInitialized && <IonReactRouter>
				<Suspense fallback={
					<IonLoading isOpen={!showLoading} />
				}>
	
					<IonSplitPane contentId="main">
						{isAuthenticated && <Menu />}

						<IonRouterOutlet id="main">
	
							{ isAuthenticated && user ? [
								// Render when is authenticated
								...[user.hasPaymentMethod ? [<Route key="/" path="/" component={Home} exact />] : []],
								<Route path="/billing" key="/billing" component={Bills} exact />
							] : (
							// Render when isn't authenticated
								<Route path="/login" component={Login} exact />
							)}
	
							<Redirect to={isAuthenticated && user ? (user.hasPaymentMethod ? '/' : '/billing') : '/login'} />
						</IonRouterOutlet>
					</IonSplitPane>
				</Suspense>
			</IonReactRouter>}

			{activeModals.map(modal => <IonModal {...modal.options} key={modal.id} isOpen onDidDismiss={() => modal.dismiss()}>
				{modal.content}
			</IonModal>)}
		</IonApp>
	);
};

export default App;
